import Box from "@mui/system/Box";
import { Button, Divider} from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "#0E1116",
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: { xs: 1, md: 4, lg: 12 },
        boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.75)",
      }}
    >
      <a href="https://www.tetherre.com/#about">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: { xs: 13, lg: 17 },
          }}
        >
          About us
        </Button>
      </a>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: "#FFFFFF" }}
      />
      <a href="https://www.tetherre.com/tether-privacy-policy/">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: { xs: 13, lg: 17 },
          }}
        >
          Privacy Policy
        </Button>
      </a>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: "#FFFFFF" }}
      />
      <a href="https://www.tetherre.com/contact-us/">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: { xs: 13, lg: 17 },
          }}
        >
          Contact us
        </Button>
      </a>
    </Box>
  );
};

export default Footer;
